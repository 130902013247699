import { toast } from "react-toastify";
import { api } from "utilities";

export const getCardByDocument = async (
  documentNumber: string,
  last4Digits: string
) => {
  try {
    const { data } = await api({}).post("/cards/validate-l4d", {
      document: documentNumber,
      last4Digits: last4Digits,
    });
    return data.id;
  } catch {
    toast.error(
      "Opss.. Erro ao cadastrar o cartão. Não foi possível validar o seu cartão."
    );
    return false;
  }
};
